<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.gideonOrderHistoryPageInfo.Label_Page_Title }}</h1>
    </header>
    <b-container class="top">
      <b-row class="top-row">
        <b-col
          sm="12"
          xs="12"
          class="top-col mb-5">
          <top-nav-search
            class="top-nav-search"
            :showDescriptionText="showDescriptionText"
            :backLabel="gideonOrderHistoryPageInfo.Label_Back_Description"
            :i18n="translations.components"
          >
          </top-nav-search>
          <camp-selector
            @camp_changed='page_load($event)'
            :onlyUSA='onlyUSA'
            :tooltip='translations.tcCurrentHome'
            class='mt-3 mb-1'
            :i18n='translations.components'
          />
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-details mb-5" >
          <data-table
            v-if='translations.components'
            :title="this.gideonOrderHistoryPageInfo.Label_MerchandiseCardTitle"
            :tooltip="tooltip"
            :fields="dataTableFields"
            :items="this.gideonOrderHistoryPageInfo.MerchandiseOrders"
            :toggle="showToggle"
            :noRecordsMessage="this.gideonOrderHistoryPageInfo.Label_NoRecordsFound"
            @addOrder="addOrderToCart"
            :i18n="translations.components"
          ></data-table>
          <!-- / Data Tables -->
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-details mb-5" >
          <data-table
            v-if='translations.components'
            :title="this.gideonOrderHistoryPageInfo.Label_ScriptureCardTitle"
            :tooltip="tooltip"
            :fields="dataTableFields"
            :items="this.gideonOrderHistoryPageInfo.ScriptureOrders"
            :toggle="showToggle"
            :noRecordsMessage="this.gideonOrderHistoryPageInfo.Label_NoRecordsFound"
            @addOrder="addOrderToCart"
            :i18n="translations.components"
          ></data-table>
          <!-- / Data Tables -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import TopNavSearch from './TopNavSearch.vue'
import DataTable from '@/components/DataTableGideonStore.vue'
import { translationMixin } from '@/mixins/translationMixin'
import CampSelect from '@/components/CampSelect.vue'

export default {
  name: 'store-orders',
  mixins: [translationMixin],
  data() {
    return {
      storeLPPayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_OrderHistory',
        org_key: ''
      },
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      showDescriptionText: false,
      showToggle: true,
      tooltip: 'Testing',
      dataTableFields: [],
      translations: {},
      onlyUSA: true
    }
  },
  components: {
    topNavSearch: TopNavSearch,
    dataTable: DataTable,
    campSelector: CampSelect,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadStoreOrderHistory: 'gideonStore/loadStoreOrderHistory',
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      updateStoreCart: 'gideonStore/updateStoreCart',
    }),
    async page_load(evt) {
      if (!evt) {
        this.storeNavPagePayload.lang = this.prefCulture
        this.storeLPPayload.lang = this.prefCulture
        this.storeLPPayload.org_key = this.userCamp.key
        await Promise.all([
          this.setLoadingStatus(true),
          this.getViewTranslations(),
          await this.getComponentTranslations('camp-select', 'data-table-store', 'store-topnav-search'),
          await this.loadStoreOrderHistory(this.storeLPPayload),
          await this.loadStoreNavigationPage(this.storeNavPagePayload),
        ]).then((results) => {
          const componentTranslations = results[2]
          this.$set(this.translations, 'components', componentTranslations)

          const {tcAmount, tcDate, tcOptions, tcOrderID} = this.translations.components['data-table-store']
          this.dataTableFields = [
            { key: 'odd_odr_id', label: tcOrderID, sortable: true },
            {
              key: 'odr_trx_date',
              label: tcDate,
              sortable: true,
              formatter: (value, key, item) => value.split(' ')[0],
            },
            {
              key: 'odd_total',
              label: tcAmount,
              sortable: true,
              formatter: (value, key, item) => '$'+ Number(value).toFixed(2),
            },
            {
              key: 'odd_key',
              label: tcOptions,
              sortable: false,
              thStyle: {
                display: 'none',
              },
              tdStyle: {
                'text-align': 'center'
              }
            }
          ]
          this.setLoadingStatus(false)
        })
      }
    },
    async addOrderToCart(data) {
      let products = data.Products.filter(p => p.prd_is_available_flag_cp === 'True')
      if (products.length === 0) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcItemsNotAvailable || 'These items are not available currently.',
          confirmButtonText: this.translations.tcOk,
        })
      }
      let cart_response = true
      let gotoToCart = false
      products.forEach(async p => {
        let cartPayload = {
          prd_key: p.prd_key,
          quantity: p.prd_quantity
        }
        await Promise.all([
          (cart_response = await this.updateCart(cartPayload))
        ]).then(() => {
        })
        if (!cart_response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorAddingItem || 'There was an error adding an item to the cart.',
            confirmButtonText: this.translations.tcOk,
          })
        } else {
          gotoToCart = true
        }
      })

      await this.loadStoreNavigationPage(this.storeNavPagePayload)
      this.setLoadingStatus(false)
      if (gotoToCart) {
        this.$router.push({ name: 'store-cart' })
      }
    },
    async updateCart(cartPayload) {
      let response = false
      let returnValue = false
      await Promise.all([
        (response = await this.updateStoreCart(cartPayload)),
      ]).then((response) => {
        if (response[0] === false) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorAddingItem || 'There was an error adding an item to the cart.',
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            returnValue = false
          })
        } else {
          returnValue =  true
        }
      })
      return returnValue
    }
  },
  computed: {
    ...mapGetters({
      gideonOrderHistoryPageInfo: 'gideonStore/gideonOrderHistoryPageInfo',
      userCamp: 'user/userCamp',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  created() {
    this.page_load()
  },
}
</script>

<!--TODO-->
<!-- resume work on order confirmation, cartcheckout, orders-->
<!-- use free item in favorites to check confirmation page -->

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}
.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}
.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}
.switch-buttons {
  margin: 0 auto;
  text-align: center;
  .merchscriptBtn {
    background-color: #747474;
  }
  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}
.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  background-color: #ffffff;
  // padding-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;
  .tile-col-title {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    .category-ddl {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
      .btn-go {
        padding: 8px;
      }
    }
  }
  .tile-col-details {
    padding: 0;
  }
  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
  }
}

.tiles {
  margin-bottom: 80px;
}

.arrow-down-icon{
    color:#000000;
    cursor: pointer;
}
.arrowup {
    cursor: pointer;
    img {
        transform: rotate(180deg);
    }
}
</style>
